/* eslint-disable no-template-curly-in-string */
import en from './en-US'
const es: typeof en = {
  forms: {
    8655: {
      alert:
        'Ahora estamos recopilando electrónicamente el formulario 8655 del IRS. Debe enviar todos los formularios para procesar la nómina y presentar los impuestos de su empresa.',
      description:
        'La Autorización del Agente Informante del Formulario 8655 del IRS autoriza a Toast Payroll (es decir, Strategy Execution Partners, LLC) a presentar y pagar impuestos federales sobre el empleo en nombre de su(s) empresa(s). Para obtener más información y revisar las instrucciones del IRS 8655, <0>irs.gov.</0>',
      descriptionContinuePayroll:
        'Para continuar procesando la nómina, necesitamos que envíe el formulario 8655 del IRS, Autorización del agente informador. El formulario autoriza a Toast Payroll (es decir, Strategy Execution Partners, LLC) a presentar y pagar impuestos federales sobre el empleo en nombre de su(s) empresa(s). Para obtener más información y revisar las instrucciones del IRS 8655, <0>irs.gov.</0>',
      download: {
        alert:
          'Puede descargar su formulario 8655 del IRS firmado. La Autorización del agente informante del formulario 8655 del IRS nos autoriza a Toast Payroll (es decir, Strategy Execution Partners, LLC) a presentar y pagar impuestos federales sobre el empleo en nombre de su empresa.',
        alertMultiple:
          'Puede descargar los formularios 8655 del IRS firmados. La Autorización del agente informante del formulario 8655 del IRS nos autoriza a Toast Payroll (es decir, Strategy Execution Partners, LLC) a presentar y pagar impuestos federales sobre el empleo en nombre de su empresa.',
        alertDownload: '<0>Descargar</0>',
        title: 'Descargar el formulario 8655 del IRS',
        options: {
          feinTitle: 'Negocios',
          feinText:
            'Descargue los formularios firmados por la empresa en la que inició sesión',
          dateTitle: 'Intervalo de fechas',
          dateText:
            'Descargar todos los formularios firmados dentro de un rango de fechas en Nómina'
        },
        loading: 'Cargando...',
        cancel: 'Cancelar',
        download: 'Descargar'
      },
      actions: {
        start: 'Iniciar',
        submitted: 'RECIBIÓ',
        submit: 'Entregar',
        back: 'Atrás'
      },
      multiple: {
        alert:
          'Ahora estamos recopilando electrónicamente la presentación del formulario 8655 del IRS. Debe enviar todos los formularios para procesar la nómina y presentar los impuestos de sus empresas.',
        title: 'Formulario 8655 Autorización del agente informate',
        table: {
          business: 'Negocio',
          status: 'Estado del Formulario 8655'
        }
      },
      validations: {
        contact: {
          required: 'Se requiere la persona de contacto',
          valid:
            'Solo se permiten caracteres alfabéticos, y (&), guión (-), signo menor que (<) y espacio.'
        },
        title: 'Se requiere un título',
        date: 'Se requiere una fecha',
        signature: {
          required: 'Se requiere la firma',
          invalid:
            'La firma digital que proporcionó no se puede aceptar. Intente firmar con su nombre nuevamente.'
        }
      },
      snackBarStatus: {
        success:
          'Formulario 8655 presentado. Puede descargar su formulario desde Informes en Vista de presentación de impuestos',
        error:
          'No se puede presentar el formulario 8655. Intente enviar el formulario nuevamente'
      },
      formFields: {
        pageHeader:
          'Formulario 8655 Informe de autorización de presentación de informes',
        taxpayer: 'Contribuyente',
        taxpayerNameField: '1a Nombre de la contribuyente',
        einField: '2 Número de identificación de empleado (EIN)',
        addressField:
          '3 Dirección (número, calle y número de habitación o suite)',
        cityState: 'Ciudad o pueblo, estado y código postal',
        contactPerson: '6 Persona de contacto',
        daytimePhone: '7 Numero de telefono diurno',
        typeHere: 'Escriba aquí',
        authorizationAgreementTitle: 'Acuerdo de autorización',
        authorizationAgreement:
          '<strong> Certifico que tengo la autoridad para ejecutar este formulario y autorizar la divulgación de información que de otro modo sería confidencial en nombre del contribuyente.</strong> <br/>  Entiendo que este acuerdo no me exime, como contribuyente, de la responsabilidad de garantizar que se presenten todas las declaraciones de impuestos y que se realicen todos los depósitos y pagos y que puedo inscribirme en el Sistema Electrónico de Pago de Impuestos Federales (EFTPS) para ver depósitos y pagos realizados en mi nombre. Si se completa la línea 15, el agente informador nombrado arriba está autorizado a firmar y rendir la planilla indicada, comenzando con el trimestre o año indicado. Si se completa alguna fecha de inicio en la línea 16, el agente informador mencionado anteriormente está autorizado a realizar depósitos y pagos comenzando con el período indicado. Cualquier autorización otorgada permanece vigente hasta que sea terminada o revocada por el contribuyente o agente declarante. Autorizo ​​al IRS a revelar información tributaria que de otro modo sería confidencial al agente informador en relación con la autoridad otorgada en la línea 15 y/o línea 16, incluidas las divulgaciones requeridas para procesar el Formulario 8655. La autoridad de divulgación entra en vigor tras la firma del contribuyente y la recepción del formulario por parte del IRS. 8655. La autoridad otorgada en el Formulario 8655 no revocará ningún Poder (Formulario 2848) o Autorización de Información Fiscal (Formulario 8821) vigente.',
        select: 'Seleccione una opción',
        certifyStatement:
          'Certifico que tengo la autoridad para ejecutar este formulario y autorizar la divulgación de información que de otro modo sería confidencial en nombre del contribuyente.',
        date: 'Fecha',
        fax: '8 Número de fax',
        title: 'Título'
      }
    }
  },
  audit: {
    action: {
      opened: 'comenzó',
      posted: 'publicó',
      reset: 'restableció',
      postponed:
        'pospuso (Fecha límite incumplida) el pago hasta el {{postponedDate}} para',
      checks: 'page en Cheques forzado (Fecha límite incumplida) para'
    },
    description: '{{whom}} {{action}} esta nómina a la(s) {{time}}'
  },
  common: {
    contactCare: 'Atención de contacto',
    earning: 'Ganancia',
    hours: 'Horas',
    amountDolar: 'Monto ($)',
    filters: {
      label: 'Filtrar',
      clear: 'Filtros claros'
    },
    action: {
      open: 'abrirse',
      run: 'agendarse',
      buttons: {
        add: 'Añadir',
        advanced: 'Acciones avanzadas',
        calculate: 'Calcular',
        cancel: 'Cancelar',
        close: 'Cerrar',
        yesContinue: 'Si, continuar',
        continue: 'Continuar',
        next: 'Siguiente',
        processing: 'Procesando...',
        okay: 'Okey',
        reset: 'Restablecer Nómina',
        submit: 'Enviar',
        view: 'Inspeccionar',
        run: 'Ejecutar la nómina',
        preparing: 'Preparando',
        calculating: 'Calculador',
        ignoreTips: 'Ignorar propinas no recibidas',
        ignoreTimesheets: 'Ignorar horas no aprobadas',
        ignoreTimesheetsAndTips: 'Ignorar tiempo y propinas no aprobadas'
      },
      choose: 'Seleccionar...'
    },
    yes: 'Si',
    no: 'No',
    modal: {
      header: '¡Lo siento! Algo salió mal.',
      message: {
        errors: {
          deduction:
            'No se pueden editar las deducciones. Por favor, espere unos minutos e intente de nuevo. Si este problema persiste, <3> comuníquese con Atención al cliente. </3>',
          reset:
            'Su nómina no pudo restablecer. Por favor, espere unos minutos e intente de nuevo. Si este problema persiste, <3> comuníquese con Atención al cliente. </3>',
          open: 'Su nómina no pudo abrirse. Por favor, espere unos minutos e intente de nuevo. Si este problema persiste, <3> comuníquese con Atención al cliente. </3>',
          calculate:
            'Su nómina no puede ser calculada. Por favor, espere unos minutos e intente de nuevo. Si este problema persiste, <3> comuníquese con Atención al cliente. </3>',
          post: 'Su nómina no pudo publicarse. Por favor, espere unos minutos e intente de nuevo. Si este problema persiste, <3> comuníquese con Atención al cliente. </3>',
          generic:
            'No fue posible aplicar cambios a la nómina, por favor contacte a soporte o inténtelo nuevamente',
          saveEarning:
            'No fue posible guardar la ganancia, espere unos minutos e intente de nuevo. Si este problema persiste, <3> comuníquese con Atención al cliente. </3>'
        },
        reset:
          'Restablecer la nómina deshará los cambios que haya realizado y devolverá la nómina a su estado inicial.',
        ignoreTips:
          'Es posible que algunos de sus empleados no reciban el pago correcto por las propinas que ganaron. Asegúrese de tener en cuenta todas las propinas ganadas antes de pagar a sus empleados.',
        ignoreTimesheets:
          'Es posible que algunos de sus empleados no reciban el pago correcto por las horas que trabajaron. Asegúrese de tener en cuenta todas las horas trabajadas antes de pagar a sus empleados.',
        ignoreTimesheetsAndTips:
          'Es posible que algunos de sus empleados no reciban el pago correcto por las horas que trabajaron y las propinas que ganaron. Asegúrese de tener en cuenta todas las horas trabajadas y las propinas ganadas antes de pagar a sus empleados.',
        highNumber: {
          header: 'Hay una cantidad muy alta en el/los campo:',
          item: '{{field}}: ${{number}}',
          footer: '¿Estás seguro de que quieres continuar con ella?'
        }
      },
      title: {
        reset: 'Restablecer Nómina?',
        confirmMissingData: '¿Estás seguro de que quieres comenzar la nómina?',
        highNumber: 'Confirme monto de ganancia'
      },
      loading: {
        reset: 'Restablecimiento de la nómina...'
      }
    },
    next: 'Próximo',
    noChange: 'Ningún cambio',
    previous: 'Previo',
    validation: {
      required: 'Necesario'
    },
    placeholders: {
      search: 'Buscar...'
    },
    employee: 'Empleado'
  },
  disclosure: {
    title:
      'Toast Processing Services LLC procesa los pagos en ciertos estados.',
    target: 'Haga clic aquí',
    description:
      '{{-link}} para obtener detalles e información sobre la licencia.',
    attention:
      '<strong> Atención a los empleadores de Maine: </strong> Haga clic en los enlaces a continuación para obtener la información de contacto de las agencias tributarias si desea verificar que los pagos de retención se hayan realizado y/o acreditado correctamente en su nombre.',
    federalWitholding: 'Retención del Empleador Federal',
    maineWitholding: 'Retención del Empleador de Maine'
  },
  employees: {
    subtitle:
      'Revisar, agregar o modificar las ganancias y deducciones de los empleados',
    title: 'Empleados'
  },
  errors: {
    schedule: {
      default:
        'No se puede programar la nómina en este momento. Póngase en contacto con el servicio de asistencia.',
      OtherPayrollScheduled:
        'Ya tiene una nómina calculada para este grupo de pago. Para calcular esta nómina, publique o edite su otra nómina.',
      PayPeriodMustBeOPENED:
        'Actualice la página. Parece que la nómina ha cambiado en otro proceso.',
      PayrollLocked:
        'Esta operación entró en conflicto con otra operación que ya estaba en marcha. Vuelva a intentar la operación.'
    },
    post: {
      NotActiveCustomer:
        'No puede publicar la nómina porque su cuenta está inactiva en este momento. Comuníquese con soporte si cree que se le impidió por error publicar la nómina.',
      IncompleteBankInformation:
        'No puede publicar la nómina porque le falta información de cuenta bancaria. Comuníquese con el servicio de asistencia si cree que se le impidió por error publicar la nómina.',
      InvalidStatus:
        'Parece que algo ha cambiado. El estado de la nómina no es válido para este tipo de acción. Por favor actualiza la página y vuelve a intentarlo.',
      IncompleteBankAccount:
        'No puede abrir esta nómina porque no tiene una cuenta bancaria vinculada. Póngase en contacto con el servicio de asistencia para añadir la cuenta bancaria adecuada.',
      PayPeriodArchived:
        'No puede publicar la nómina porque actualmente está archivada. Para publicar, por favor desarchive primero.'
    },
    import: {
      LockConflict:
        'Actualmente se está ejecutando el proceso interno de importe de Hojas de Tiempo. Una vez que finalice, podrá avanzar al siguiente paso de la nómina.',
      UnknownError:
        'No se puede importar la nómina. Vuelva a intentarlo o póngase en contacto con soporte técnico.'
    },
    run: {
      PayPeriodLockConflict:
        'Por favor actualice la página e inténtelo de nuevo.',
      PreviousYearOpen:
        'Antes de procesar la (s) nómina (s) en un nuevo año calendario, se debe cerrar el año calendario anterior. Esto ocurre automáticamente el 1 de enero o antes (dependiendo de cuándo se procese la última nómina del año actual).\n\nEsta nómina no se puede procesar actualmente, ya que el año calendario anterior aún no se ha cerrado. Debería cerrarse automáticamente en los próximos días. Si necesita procesar con urgencia, comuníquese con el soporte.',
      IncompleteBankAccount:
        'No puede abrir esta nómina porque no tiene una cuenta bancaria vinculada. Póngase en contacto con el servicio de asistencia para añadir la cuenta bancaria adecuada.',
      InvalidStatus:
        'Esta nómina ha cambiado de estado en otro proceso. Actualice la página y vuelva a intentar la operación.'
    },
    saveEarning: {
      PayTypeRestrict:
        'No se pueden guardar ganancias porque el tipo de pago lo restringe.'
    },
    outOfBalance:
      'Su nómina está fuera de balance por ${{value}}. Trate recalculando la nómina y si el problema persiste por favor pónganse en contacto con {{link}} para obtener ayuda',
    prohibited: 'Prohibido',
    generic:
      'Error desconocido, Vuelva a intentarlo o póngase en contacto con soporte técnico'
  },
  payrolls: {
    list: {
      upcoming: 'Próximas Nóminas',
      past: 'Nóminas Pasadas'
    },
    pastPayrollsTab: {
      noPast: 'No hay nóminas pasadas',
      name: 'Nombre',
      payPeriod: 'Período de pago',
      payDate: 'Fecha de pago',
      postedAt: 'Publicado en',
      postedBy: 'Publicado por',
      totalExpense: 'Gasto Total'
    },
    dueDate: {
      today: 'Hoy',
      due: 'Vence el {{deadline}}'
    },
    header: {
      manageLinkText: 'Administrar hojas de tiempo',
      title: 'Nómina'
    },
    title: {
      payrollReminders: 'Recordatorios de nómina',
      expenses: 'Gastos',
      expenseReportManager: 'Administrador de informes de gastos',
      quickActions: 'Acciones Rapidas',
      payrollManualChecks: 'Cheques manuales',
      payrollToDos: 'Tareas de nómina pendientes',
      payrollQuickCalcs: 'Cálculos rápidos'
    },
    upcomingPayrollTile: {
      archive: {
        header: '¿Nómina de archivo?',
        body: 'Al archivar, confirma que no desea procesar esta nómina en particular. Las horas o ganancias vinculadas a esta nómina no se procesarán ni se pagarán.',
        cancel: 'Cancelar',
        ok: 'Archivo',
        tooltip: 'Archivar la Nómina'
      },
      runEarly: {
        header: 'Iniciar Nómina Anticipadamente?',
        body: 'La Fecha de Pago de esta nómina está a más de una semana de distancia. Se alienta el procesamiento temprano, pero asegúrese de que esta sea la nómina que desea procesar y que la Fecha de Pago esté en línea con las expectativas.',
        cancel: 'Cancelar'
      },
      continuePayroll: 'Continuar',
      dueDate: 'FECHA LÍMITE',
      unableToPost:
        'Su empresa no puede procesar la nómina debido a un problema con su cuenta de Toast Payroll. <0>Contact care</0>',
      noUpcoming: 'No tienes nóminas próximas.',
      offcycle: 'Ciclo de apagado: ',
      payDate: 'FECHA DE PAGO',
      payPeriod: 'PERÍODO DE PAGO',
      payGroup: 'Grupo de pago',
      runningLoader: {
        texts: {
          employees: 'Incorporar a los empleados a la nómina',
          manual: 'Encontrar cheques manuales',
          todos: 'Encontrar tareas pendientes de nómina',
          unapproved: 'Buscando hojas de horas no aprobadas',
          voids: 'Encontrar cheques nulos'
        },
        message: '¿Qué está pasando detrás de escena?'
      },
      runPayroll: 'Iniciar nómina',
      previewPayroll: 'Vista previa',
      badgeMessage: {
        ready: 'LISTO PARA CORRER',
        unapprovedTimesheets: 'HOJAS NO APROBADAS',
        unreceivedTips: 'PROPINAS SIN RECIBIR',
        unapprovedTimeAndTips: 'HOJAS Y TIEMPOS NO APROBADOS',
        unableToPost: 'NO SE PUEDE PROCESAR NÓMINA'
      }
    }
  },
  preview: {
    header: {
      message:
        'Vista previa de elementos adjuntos a esta nómina. Cuando esté seguro de que todo está contabilizado, haga clic en Siguiente para traer todos los elementos y avanzar al siguiente paso.',
      title: 'Vista Previa'
    },
    loaderMsg: 'Importando horas, ganancias y deducciones…',
    postDeadline: 'FECHA LÍMITE DE PUBLICACIÓN',
    messages: {
      description: '{{amount}} {{title}} a {{whom}} ({{author}})'
    },
    importAudit: {
      description: '{{filename}} importado por {{author}}'
    },
    expenses: {
      description: '{{amount}} Gastos Aprobados'
    },
    timesheets: {
      addAlohaImport: 'Agregar importación Aloha',
      viewAlohaImport: 'Ver importación Aloha',
      addCustomImport: 'Agregar importación personalizada',
      viewCustomImport: 'Ver importación personalizada',
      approved: 'Hojas de tiempo aprobadas',
      noApproved: 'No se encontraron hojas de tiempo aprobadas',
      title: 'Hojas de horas',
      unapprovedNotPaid: 'El tiempo no aprobado no se pagará en la nómina'
    }
  },
  preflight: {
    stepper: 'Vista previa de nómina',
    activity: {
      empty: 'No hay actividad reciente para esta nómina.',
      expense:
        'Se aprobó un informe de gastos por <strong>{{amount}} para {{employeeName}}</strong>',
      expensePlural:
        'Se aprobaron los informes de gastos por <strong>{{amount}} para {{employeeName}}</strong>',
      expenseCombined:
        'Se aprobaron los informes de gastos por <strong>{{amount}} para {{numEmployees}} empleados</strong>',
      title: 'Actividad reciente',
      message:
        '{{author}} {{action}} un <strong>{{name}} de {{amount}} para {{whom}}</strong>',
      action: {
        recorded: 'registró',
        approved: 'aprobó',
        scheduled: 'programó'
      }
    },
    rollover: {
      title: 'No envió {{totalCount}} {{ifPlural}} en {{previousYear}}',
      nonAdminTitle:
        'Su empresa no envió {{totalCount}} {{ifPlural}} en {{previousYear}}',
      pluralPayrolls: 'nóminas',
      singularPayroll: 'nómina',
      descriptionLessThan5:
        'Para poder pagarle a su equipo en {{currentYear}}, debemos cerrar todas las nóminas que no haya enviado en {{previousYear}}. Archive las nóminas para continuar pagando a su equipo en {{currentYear}} o contáctenos para enviar las nóminas de {{previousYear}}.',
      descriptionGreaterThan5:
        'Para poder pagarle a su equipo en {{currentYear}}, debemos cerrar todas las nóminas que no haya enviado en {{previousYear}}. Archive las nóminas para continuar pagando a su equipo en {{currentYear}} o contáctenos para enviar las nóminas de {{previousYear}}.',
      nonAdminDescription:
        'Para poder pagarle a su equipo en {{currentYear}}, debemos cerrar todas las nóminas que su empresa no envió en {{previousYear}}. Contáctanos para continuar pagando a tu equipo en {{currentYear}}.',
      table: {
        payGroup: 'Grupo de pago',
        payFrequency: 'Frecuencia de pago',
        payPeriod: 'Período de pago',
        checkDate: 'Comprobar Fecha'
      },
      contactUs: 'Contáctanos',
      archive: 'Archivar nóminas',
      archivingPayrolls: 'Archivando nómina(s)',
      archivedPayrolls: 'Nómina(s) archivada(s)'
    },
    employeeCard: {
      delta: 'Cambio desde el último período de pago',
      hourly: 'Empleados por hora',
      salary: 'Asalariados',
      title: 'Empleados',
      viewDetails: 'Ver detalles',
      subtitle: ' a día de hoy'
    },
    tipsCard: {
      title: 'Propinas',
      amount: 'Cantidad total',
      overtime: 'La horas extraordinarias',
      unreceived: '{{number}} días sin recibir'
    },
    hoursCard: {
      title: 'Horas'
    },
    error:
      'Lo sentimos, no pudimos cargar esta información. Intente actualizar la página. Si el problema persiste, comuníquese con atención al cliente.',
    modals: {
      taxTask: {
        header:
          'Sus impuestos sobre la nómina no están preparados para su próxima presentación',
        body: 'Toast está a punto de preparar sus impuestos sobre la nómina trimestrales. Actualice su información fiscal para evitar rechazos y cargos fiscales.',
        buttons: {
          ignore: 'Ignorar y aceptar el riesgo',
          resolve: 'Resuelva los problemas fiscales'
        }
      },
      timesheets: {
        title: 'Horas por ganancias',
        subtitle: 'Desglose de todas las horas trabajadas por ingresos',
        header: 'Ganancia',
        emptyState: 'No hay hojas de tiempo asociadas con esta nómina.'
      },
      tips: {
        title: 'Propinas',
        emptyState: 'No hay propinas asociadas con esta nómina.',
        subTitle: {
          normal: 'Estas propinas serán pagadas a sus empleados en esta nómina',
          pooled:
            'Estas propinas incluyen propinas en efectivo registradas y no en efectivo. Envíe propinas desde Administración de propinas en POS a Nómina antes de aprobar sus hojas de tiempo.'
        },
        status: {
          inProgress: 'EN PROGRESO',
          ready: 'LISTAS',
          noTips: 'SIN PROPINAS'
        },
        header: {
          days: 'Días trabajados en esta nómina',
          tips: 'Propinas'
        },
        footer: {
          viewTimeAndTips: 'Ver tiempos y propinas',
          viewTipsInPayroll: 'Ver propinas en Payroll',
          viewTipsManagement: 'Ver Manejo de Propinas'
        }
      },
      employees: {
        title: 'Comparación de empleados',
        tabs: {
          compare: 'Comparar con nómina anterior',
          employees: 'Todos los empleados',
          content: {
            compare: {
              incoming: {
                title: 'Empleados entrantes',
                description:
                  'Empleados a los que se les pagará en este ciclo pero no se les pagó en el último ciclo de esta nómina. Es posible que no se incluyan los empleados con tareas pendientes de nómina.',
                emptyState: 'Hay 0 empleados entrantes en esta nómina.'
              },
              outgoing: {
                title: 'Empleados salientes',
                description:
                  'Empleados a los que no se les paga pero se les pagó en este ciclo de nómina.',
                emptyState: 'Hay 0 empleados salientes en esta nómina.'
              }
            },
            employees: {
              title: 'Empleados pagados en la nómina',
              description:
                'Es posible que no se incluyan los empleados con tareas pendientes de nómina.',
              emptyState:
                'Hay 0 empleados a pagar en esta nómina en este momento. Esto puede deberse a que los empleados aún no han trabajado dentro de este ciclo de pago. Si cree que se trata de un problema, póngase en contacto con el servicio de atención al cliente.'
            }
          }
        }
      }
    },
    timesheetsPanel: {
      delta: 'Cambio desde esta hora último período de pago',
      overtimeHours: 'Horas Extras',
      title: 'Hojas de Tiempo',
      totalHours: 'Total de Horas',
      unapproved: '{{number }} no aprobado',
      unapprovedNoCount: 'No aprobado',
      viewDetails: 'Ver detalles'
    },
    hoursGraph: {
      legend: {
        currentPayPeriod: 'Horas a pagar en este período',
        previousPayPeriod: 'Horas pagadas ultimo periodo'
      },
      title: 'Horas',
      emptyState: 'No se han registrado hojas de tiempo para esta nómina',
      timesheetsLink: 'Ver por ingresos'
    }
  },
  spreadsheet: {
    stepper: 'Ganancias de los empleados',
    empty: {
      title: 'No hay empleados en esta nómina',
      supplement:
        'Cuando los empleados de este grupo de pago tengan ganancias para este período de pago, aparecerán aquí'
    },
    card: {
      employee: {
        deleteEmployee: 'Remover Empleado(a)',
        payViaCheck: 'Pagar con Cheque',
        grossToNet: 'Calcular Bruto a Neto',
        additionalPayCheck: 'Crear Cheque de Pago Adicional'
      }
    },
    payBy: {
      title: 'Gestionar los cheques de pago de {{employee}}'
    },
    paidByCheck: 'Se le pagará al empleado(a) con cheque*',
    payViaCheck: {
      title: 'Pagar con cheque al empleado: {{employee}}',
      text: '¿Desea anular el método de pago predeterminado para pagar a través de un cheque de pago en papel?',
      description:
        'Si elige anular el método de pago predeterminado, el empleado recibirá un cheque de pago físico en lugar de lo que podría ser un depósito en su(s) cuenta(s) bancaria(s) registrada(s). Esta función es útil si el empleado ha solicitado un pago único por cheque, o en los casos en que usted prefiere entregar un pago en particular (por ejemplo, un bono de fin de año) mediante un cheque de pago en papel.',
      payAllDropdown: 'Pagar todo con cheque',
      payAllTitle: '¿Pagar todo con cheque?',
      payAllBody:
        'Con esta opción, todos los empleados en esta nómina recibirán un cheque de pago en físico en vez de lo que de otra manera sería un depósito directo en sus cuentas bancarias registradas. Por favor úselo con cuidado, ya que esto no se puede deshacer.'
    },
    actions: {
      confirm: {
        body: 'Estás a punto de calcular tu nómina. Tenga en cuenta que este NO es el paso final de la nómina. Una vez que se calcula la nómina, tendrá la oportunidad de revisar la precisión y finalizar / publicar la nómina.',
        header: 'Calcular nómina'
      }
    },
    employeeList: {
      employee: 'Empleado',
      hours: 'Horas',
      totalEarnings: 'Ganancias'
    },
    deductions: {
      table: {
        headings: {
          deduction: 'Deducción',
          amount: 'Monto'
        }
      },
      form: {
        add: {
          title: 'Agregar Deducción: {{employee}}',
          bulkTitle: 'Añadir Deducciónes a Granel'
        },
        edit: {
          title: 'Editar Deducción: {{employee}}'
        },
        addDeduction: 'Agregar deducción',
        searchEmployees: 'Buscar empleados...',
        employee: {
          label: '¿Qué empleados recibirán esta deducción?',
          blank: 'No hay empleados que coincidan'
        },
        amount: 'Monto',
        deductionType: 'Tipo de Deducción',
        flatAmount: 'Cantidad fija',
        percentageGrossPay: '% del Sueldo Bruto',
        validation: {
          employees: {
            min: 'Esta deducción debe aplicarse a al menos un empleado'
          }
        }
      }
    },
    earnings: {
      table: {
        headings: {
          earning: 'Ganancias',
          location: 'Ubicación',
          job: 'Trabajo',
          rate: 'Tarifa ($/hora)',
          hours: 'Horas',
          amount: 'Monto ($)'
        },
        weekTwo: ' (Semana 2)'
      },
      form: {
        add: {
          title: 'Agregar Ganancia: {{employee}}',
          bulkTitle: 'Añadir Ganancias a Granel'
        },
        edit: {
          title: 'Editar Ganancia: {{employee}}'
        },
        addEarning: 'Agregar ganancia',
        searchEmployees: 'Buscar empleados ...',
        employee: {
          label: '¿Qué empleados recibirán esta ganancia?',
          blank: 'No hay empleados que coincidan'
        },
        earningRate: {
          label: 'Tasa de ganancia',
          labelOT: 'Tasa de ganancia base'
        },
        workWeek: {
          label: 'Semana laboral asociada',
          helper:
            '¿A qué semana del período de pago se aplica esta ganancia? Esta selección se utiliza para informar los cálculos de recuperación de propinas y horas extra.'
        },
        earningCode: {
          label: 'Código de ganancias'
        },
        hours: {
          label: 'Horas',
          salaried: {
            helper:
              'Esta es una ganancia asalariada, por lo tanto, las horas se utilizan solo en los informes.'
          }
        },
        amount: {
          label: 'Cantidad'
        },
        checkCode: {
          label: 'Frecuencia de ganancias',
          helper:
            'Esto ayuda a informar los impuestos. Esto no crea nuevas ganancias en futuras nóminas.'
        },
        isFirstWeek: {
          first: 'Primero',
          second: 'Segundo'
        },
        validation: {
          employees: {
            min: 'Esta ganancia debe aplicarse a al menos un empleado'
          }
        },
        actions: {
          change: 'Cambiar la ganancia',
          add: 'Agregar ingresos'
        },
        advancedOptions: {
          label: 'Opciones avanzadas',
          description:
            '(Empresa, ubicación, trabajo, tarifa, semana laboral, frecuencia de ingresos)'
        }
      }
    },
    addPayCheck: {
      title: 'Crear cheque de pago adicional: {{name}}',
      helperText:
        'Esto ayuda a informar los impuestos. Esto no crea nuevas ganancias en futuras nóminas.',
      confirmButton: 'Agregar cheque de pago'
    },
    addEmployee: {
      title: 'Agregar nuevo empleado',
      confirmButton: 'Agregar empleado',
      helperText: 'Puede agregar cualquier empleado de este grupo de pago.',
      showTerminated: 'Incluir a los empleados despedidos'
    },
    deleteEmployee: {
      title: 'Eliminar empleado: {{name}}',
      loadingText: 'Eliminando...',
      confirmButton: 'Eliminar empleado',
      helperText:
        'La eliminación elimina a este empleado y todas las ganancias / deducciones asociadas de la nómina. ¿Estas seguro que quieres borrarlo?'
    },
    g2n: {
      modal: {
        error:
          'Lo sentimos, no podemos calcular el valor bruto a neto en este momento',
        title: 'Vista previa de bruto a neto: {{name}}',
        totals: {
          gross: 'Ingresos completos',
          deductions: 'Deducciones',
          taxes: 'Taxes',
          net: 'Net'
        }
      },
      tables: {
        earnings: { title: 'Ganancias' },
        deductions: { title: 'Deducciones' },
        tax: {
          title: 'Impuestos',
          headings: {
            tax: 'Impuesto',
            amount: 'Monto',
            wages: 'Salarios del sujeto',
            ytd: 'el último año'
          }
        }
      }
    },
    page: {
      addEmployee: 'Agregar empleado',
      addBulkEarnings: 'Ingresos agregados a granel',
      addBulkDeductions: 'Agregar deducciones masivas',
      allLocations: 'Todas las localizaciones',
      allJobs: 'Todos los trabajos',
      employeeName: 'Nombre de empleado'
    },
    condensedView: 'Vista condensada'
  },

  review: {
    stepper: 'Revisar y enviar',
    sections: {
      paperChecks: {
        header: 'Cheques en Papel',
        instructions:
          'Por favor recuerde utilizar las existencias de cheques de Toast para garantizar un formato de impresión adecuado.',
        download: 'Descargar cheques en papel',
        audit: '{{employee}} Descargó Cheques a la(s) {{time}}',
        loading:
          'El archivo de cheques en papel se está generando actualmente. Esto puede tardar unos minutos, así que no dudes en volver a consultar más tarde.',
        description:
          '{{count}} empleados exigen el pago con cheque en papel. Por favor imprima y entregue cheques el {{checkDate}}.',
        settings: 'Configuración de Entrega de Cheques de Pago',
        error:
          'Lo sentimos, no pudimos cargar la información del cheque en papel. Actualice la página o vuelva a intentarlo más tarde.',
        showMore: 'Mostrar más',
        showLess: 'Muestra menos',
        headerNoChecks:
          'Ningún empleado en esta nómina está programado para recibir cheques en papel. Para modificar la configuración de entrega de cheques, vaya a <0>Configuración de Entrega de Cheques de Pago</0>.'
      },
      payownway: `Está utilizando el método escrito por usted mismo para pagar a los empleados sin depósito directo. 
      Por lo general, esto significa que usted escribirá cheques en vivo de su propio talonario de cheques o entregará los pagos netos de los empleados en efectivo. 
      Para obtener una lista de los empleados que necesita para asegurarse de que se les paga,
      consulte el informe de registro de cheques a continuación.`,
      toastPrinted: {
        header:
          'Toast Payroll entregará <strong>{{checks}} cheques<strong/> para <strong>{{ees}} empleados</strong> a sus direcciones registradas en la <0>Configuración de Entrega de Cheques de Pago</0>.',
        table: {
          headers: {
            destination: 'Destino del paquete de cheques',
            checks: '# de cheques',
            trackingNumber: 'Rastreo',
            trackingNumberUnavailable: 'Número de rastreo no disponible'
          }
        },
        warning:
          'La entrega de cheques generalmente cuesta $20/paquete. Ahorre en futuros costos de envío de nómina cambiando a Autoescrito o Autoimpreso.'
      }
    },
    accountingSummary: {
      cashTips: 'Propinas en efectivo',
      cashTipsV1: 'Efectivo (no pagado en nómina)',
      grossPay: 'Sueldo bruto',
      nonCashTips: 'Propinas que no sean en efectivo',
      nonCashTipsV1: 'No efectivo (pagado en nómina)',
      taxes: 'Impuestos del empleador',
      tips: 'Propinas',
      title: 'Resumen contable',
      total: 'Cantidad total',
      totalV1: 'Total',
      lastPayroll: 'Última nómina',
      estimatedWithdrawal: 'Retiro estimado',
      withdrawalTooltip:
        'Toast debitará los fondos estimados, sin incluir los cheques en papel ni las propinas pagadas en efectivo.'
    },
    action: {
      close: 'Cerrar',
      cancel: 'Enviar más tarde',
      cancelV1: 'Publicar más tarde',
      edit: 'Previo',
      importTaxes: 'Importar Impuestos',
      makeAdjustments: 'Hacer Ajustes',
      post: 'Enviar nómina',
      postPayroll: 'Publicar nómina'
    },
    calculating: {
      behindScenes: {
        message: '¿Qué está pasando mientras tanto?',
        texts: {
          deductions:
            'Asegurando que las deducciones de los empleados estén dentro de los límites',
          directDeposits:
            'Buscando información de depósito directo de los empleados',
          employeeTaxes: 'Cálculo de los impuestos de los empleados',
          minimumWage: 'Asegurando que los empleados ganen el salario mínimo',
          netPay: 'Cálculo del pago neto',
          reports: 'Generando reportes',
          taxableIncome: 'Cálculo de la renta imponible',
          vendor: 'Buscando información del proveedor para pagos de deducción'
        }
      },
      title: 'Calculando Nómina...'
    },
    loading: 'Cargando Nómina...',
    checkDate: 'Fecha del Cheque',
    employeeEarnings: {
      employeeCount: 'Cantidad de Empleados',
      title: 'Ganancias del Empleado',
      totalEarnings: 'Ganancias'
    },
    employeeSummary: {
      hoursPaid: 'Horas pagadas',
      onPayroll: 'Empleados en nómina',
      otHours: 'OT',
      paidViaCheck: 'Pagado con cheque',
      paidViaDirectDeposit: 'Pagado con depósito directo',
      regularHours: 'Regular',
      salariedEmployees: 'Asalariados',
      title: 'Resumen del Empleado'
    },
    header: {
      message:
        'Verifique nuevamente su nómina antes de enviarla. Este es el ultimo paso!',
      title: 'Verificar'
    },
    modals: {
      edit: {
        error: {
          message:
            'No fue posible modificar la nómina, por favor contacte a soporte o inténtelo nuevamente.',
          title: 'No fue posible modificar la nómina'
        }
      },
      post: {
        signature: {
          title: 'Se requiere su firma para el procesamiento de nómina',
          description:
            'Envíe la firma del titular de la cuenta bancaria que se imprimirá en cualquier cheque físico que Toast pueda generar en su nombre.',
          table: 'Cuentas bancarias',
          clear: 'Borrar',
          caption:
            'Si tiene varias cuentas bancarias para su empresa y desea utilizar firmas distintas para cada una, comuníquese con nuestro equipo de atención al cliente. Al firmar aquí, usted da fe de que es el titular de la cuenta bancaria y tiene la autoridad para firmar cheques para esta cuenta.'
        },
        confirmation: {
          button: 'Enviar nómina',
          buttonV1: 'Publicar Nómina',
          message:
            'Al enviar, usted reconoce que ha revisado esta nómina para verificar su integridad y precisión. Esta acción no se puede deshacer.',
          buttonNext: 'Siguiente',
          title: '¿ Enviar Nómina ?',
          achConsent: {
            confirm:
              'I agree to be bound by the Toast Payroll & Team Management ACH Authorization',
            legal:
              'By checking the box below, Merchant agrees to be bound by the <0>Toast Payroll & Team Management ACH Authorization</0>, authorizing Toast, Inc., StratEx HoldCo, LLC, Toast Processing Services LLC, Strategy Execution Partners, LLC and their designated agents and representatives (each, as applicable to the activities that such party may perform) to initiate one-time and recurring credits and debits, as applicable, to the Bank Account(s) ending in {{bankAccounts}}, pursuant to the Toast Payroll & Team Management Service Agreement (the “Agreement”).',
            title: 'We need your ACH authorization to proceed.',
            titleUpdated:
              'You have modified a bank account associated with your Toast Payroll account; we need your ACH authorization to proceed.'
          },
          prodValidation: {
            title: '¿Estás segura de que quieres enviar nómina?',
            description:
              'Está a punto de realizar cambios en una cuenta de cliente de nómina real. No envíe la nómina a menos que esté procesando la nómina en nombre del cliente.',
            checkbox:
              'Tengo la responsabilidad y el permiso para procesar la nómina de este cliente.'
          },
          statusCheck: 'Comprobando el estado de la publicación...'
        },
        error: {
          message:
            'No fue posible publicar la nómina, por favor contacte a soporte o inténtelo nuevamente.',
          title: 'No fue posible publicar la nómina'
        },
        missedDeadline: {
          button: 'Publicar',
          title: 'Ha perdido la fecha de vencimiento de esta nómina',
          description:
            'Seleccione cómo desea pagar a sus empleados y continúe con el siguiente paso para procesar la nómina',
          postponeOption: 'Adjunte esta nómina a la próxima fecha de pago',
          postponeMsg: 'Pague a sus empleados antes del {{newCheckDate}}',
          payViaCheckOption: 'Pagar con cheque en papel',
          payViaCheckMsg: 'Imprima los cheques en papel de sus empleados'
        }
      }
    },
    noteworthy: {
      EMPLOYEES_WITHOUT_NETPAY: '{{count}} empleado con salario neto de $0',
      EMPLOYEES_WITHOUT_NETPAY_NONE: 'No hay empleados con $0 de pago neto',
      EMPLOYEES_WITHOUT_NETPAY_plural:
        '{{count}} empleados con salario neto de $0',

      EMPLOYEES_WITH_TIP_MAKEUP: '{{count}} empleado con tip makeup',
      EMPLOYEES_WITH_TIP_MAKEUP_NONE: 'No empleados con tip makeup',
      EMPLOYEES_WITH_TIP_MAKEUP_plural: '{{count}} empleados con tip makeup',

      TERMINATIONS: '{{count}} empleado despedido pagado',
      TERMINATIONS_NONE: 'No hay empleados despedidos pagados',
      TERMINATIONS_plural: '{{count}} empleados despedidos pagados',

      titleUpdated: 'Destacados y Excepciones',
      title: 'Notable',
      UNPAID_ACTIVE_EMPLOYEES: '{{count}} empleado activo no pagado',
      UNPAID_ACTIVE_EMPLOYEES_NONE: 'Todos los empleados activos pagados',
      UNPAID_ACTIVE_EMPLOYEES_plural: '{{count}} empleados activos no pagados'
    },
    payDate: 'Fecha de pago',
    payrollSummary: {
      checkDate: 'Fecha del cheque',
      postDeadline: 'Fecha límite de presentación',
      postDeadlineV1: 'Fecha límite de publicación',
      payperiod: 'Período de pago',
      title: 'Resumen de Nómina',
      bankAccount: 'Cuenta bancaria'
    },
    postDeadline: 'FECHA LÍMITE DE PUBLICACIÓN',
    postedConfirmation: {
      return: 'Regresar al Panel de Configuración del Pago de Nómina',
      subTitle:
        'Los cheques y los detalles de depósito directo están disponibles a continuación.',
      title: '¡Nómina pagada correctamente!'
    },
    reports: {
      checkRegisterReport: 'Check register',
      directDepositReport: 'Direct deposit list',
      employeeDetailReport: 'Employee detail',
      employeeDetailReportByDept: 'Employee detail by dept',
      employeeDetailReportByPosition: 'Employee detail by position',
      laborDistributionReport: 'Labor distribution',
      payrollSummaryReport: 'Payroll summary',
      payrollExceptionReport: 'Payroll exception',
      employeeComparisonReport: 'Employee comparison',
      laborDistributionReportByDepartment:
        'Distribución laboral por departamento',
      laborDistributionReportByLocation: 'Distribución laboral por ubación',
      laborDistributionReportByJob: 'Distribución laboral por trabajo',
      journalEntryReport: 'Informe de asientos de diario”',
      checkRegisterReportV1: 'Check Register',
      directDepositReportV1: 'Direct Deposit List',
      employeeDetailReportV1: 'Employee Detail',
      employeeDetailReportByDeptV1: 'Employee Detail by Dept',
      employeeDetailReportByPositionV1: 'Employee Detail by Position',
      laborDistributionReportV1: 'Labor Distribution',
      payrollSummaryReportV1: 'Payroll Summary',
      payrollExceptionReportV1: 'Payroll Exception',
      employeeComparisonReportV1: 'Employee Comparison',
      laborDistributionReportByDepartmentV1:
        'Distribución Laboral por Departamento',
      laborDistributionReportByLocationV1: 'Distribución Laboral por Ubación',
      laborDistributionReportByJobV1: 'Distribución Laboral por Trabajo',
      journalEntryReportV1: 'Informe de Asientos de Diario”',
      title: 'Reportes'
    },
    withdrawalSummary: {
      title: 'Recibo de retiro de nómina de Toast',
      confirmation: 'Código de confirmación: {{payperiodUuid}}',
      description: 'Se debitarán sus cuentas bancarias:',
      liveChecks: '<0>{{liveChecks}}</0> page en cheques',
      directDeposits: '<0>{{directDeposits}}</0> por depósito directo',
      vendorPayments: '<0>{{vendorPayments}}</0> para pagos a proveedores',
      payrollFees:
        '<0>{{collectionFees}}</0> para las tarifas de Nómina de Toast',
      taxes:
        '<0>{{taxes}}</0> para impuestos (no incluye los créditos solicitados)',
      nonCashReminder:
        'Este recibo no incluye retiros que no sean en efectivo. Consulte el <0>Informe de resumen de nómina</0> para obtener detalles adicionales.'
    },
    withdrawalEstimate: {
      title: 'Retiro de Nómina Estimado',
      description: 'Sus fondos estimados para cubrir esta nómina',
      toastFunds: {
        description: 'Fondos a ser recaudados por Toast',
        value: '<0>{{value}}</0> para retiros de Toast'
      },
      externalFunds: {
        taxes: '<0>{{value}}</0> por impuestos no debitados',
        description: 'Fondos NO recaudados por Toast',
        value:
          '<0>{{value}}</0> para cheques en papel de empleados o proveedores'
      },
      note: `Verá un recibo de retiro final que contiene los fondos que Toast cobrará de su(s) cuenta(s) bancaria(s) para esta nómina. 
          Consulte el <0>Informe de resumen de nómina</0> para obtener detalles adicionales.`
    },
    missedDeadline: {
      error:
        'No se pudo encontrar una nueva fecha de verificación. Póngase en contacto con el servicio de asistencia.'
    }
  },
  reports: {
    employeeComparison: {
      title: 'Comparación de horas y salarios de los empleados',
      table: {
        employeeName: 'Nombre de empleado',
        previousHours: 'Horas anteriores',
        currentHours: 'Horas actuales',
        change: 'Transformación',
        previousGrossPay: 'Pago bruto anterior',
        currentGrossPay: 'Pago bruto actual'
      },
      totals: 'Totales',
      emptyText: 'No se encontraron registros'
    }
  },
  unapproved: {
    unapprovedTimesheets: '{{number}} Hojas de tiempo sin aprobar',
    unapprovedTimesheets_SINGULAR: '{{number}} Hoja de tiempo sin aprobar'
  }
}

export default es
